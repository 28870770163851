.gallery {
    margin-bottom: 84px;
    figure {
        margin: 0;
        img {
            object-fit: cover; } }
    &-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between; } }
.advantages {
    &__title {
        margin-bottom: 35px;
        font-size: 100px;
        font-weight: 300;
        line-height: 100px;
        color: #31917c; } }
.advantage {
    display: flex;
    margin-bottom: 25px;
    &-img {
        width: 30%;
        position: relative;
        margin-bottom: auto; }
    &__image {
        width: 100%; }
    &__numb {
        position: absolute;
        bottom: 0;
        right: 0;
        opacity: 0.45;
        color: #ffffff;
        font-size: 108px;
        font-weight: 400;
        z-index: 2;
        line-height: 90px; }
    &-main {
        display: flex;
        align-items: center;
        color: #ff6600;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
        margin-bottom: 21px;
        letter-spacing: 0.38px;
        &__img {
            margin-right: 14px; }
        &__title {} }
    &-text {
        background-color: white;
        padding: 11px 14px 28px 29px;
        width: 70%;
        color: #000000;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        // margin-bottom: auto
        p {
            display: block;
            margin-bottom: 15px;
            &:last-child {
                margin: 0; } } } }
.galleryAdv {
    margin-bottom: 115px;
    position: relative;
    &:before {
        width: 200%;
        left: 50%;
        transform: translateX(-50%) rotate(-15deg);
        height: 1975px;
        background-color: #e0e0e0;
        top: 480px;
        z-index: -1; }
    figure {
        margin: 0;
        img {
            object-fit: cover; } }
    &-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
    .card-columns {
        column-count: 2; } }
