.contacts {
    margin-bottom: 38px;
    &__title {
        color: #ff6600;
        font-size: 100px;
        font-weight: 300;
        color: #31917c;
        margin-bottom: 40px; }
    &-info {
        position: relative;
        padding-left: 11px;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        &:before {
            width: 4px;
            height: 25px;
            background-color: #ff6600;
            left: 0;
            top: 0; }
        &__title {
            margin-bottom: 15px; }
        &-descr {
            a {
                color: black;
                text-decoration: none; } } } }
.map {
    border-top: 6px solid #e1e1e1;
    border-bottom: 6px solid #e1e1e1; }
