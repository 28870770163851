.design {
    margin-bottom: 87px;
    &__title {
        text-align: center;
        padding: 24px 0;
        color: #555555;
        font-size: 61px;
        font-weight: 300;
        margin-bottom: 63px;
        &:after {
            height: 3px;
            width: 241px;
            background-color: #555555;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%); }
        &:before {
            height: 3px;
            width: 241px;
            background-color: #555555;
            top: 0;
            left: 50%;
            transform: translateX(-50%); } }
    &__descr {
        text-align: center;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 94px; } }
.fiveImages {
    margin-bottom: 108px;
    &-title {
        &__numb {
            max-width: 154px;
            object-fit: cover;
            margin-bottom: 35px; }
        h3 {
            color: #ff6600;
            font-family: Roboto;
            font-size: 40px;
            font-weight: 300;
            text-transform: uppercase;
            letter-spacing: 0.01px; } }
    &-item {
        display: flex;
        justify-content: space-between; }
    .image {
        position: relative;
        &__title {
            position: absolute;
            top: 18px;
            left: 3px;
            font-size: 34px;
            font-weight: 300;
            color: #555555; } } }
.gradient {
    position: relative;
    margin-bottom: 100px;
    &-title {
        position: absolute;
        right: 0;
        top: -114px;
        max-width: 311px;
        &__numb {
            object-fit: cover;
            margin-bottom: 35px;
            margin-left: auto;
            display: block; }
        &__descr {
            color: #ff6600;
            font-size: 40px;
            font-weight: 300;
            text-transform: uppercase;
            letter-spacing: 0.01px; } } }
.colors {
    margin-bottom: 87px;
    &-title {
        &__numb {
            margin-bottom: 35px; }
        &__descr {
            color: #ff6600;
            font-size: 40px;
            font-weight: 300;
            text-transform: uppercase;
            letter-spacing: 0.01px; } } }
.color {
    margin: 0 auto;
    position: relative;
    &__title {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #ffffff;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        text-align: center;
        letter-spacing: 0.11px; } }
.consultation {
    background-color: #31917c;
    padding: 31px 0;
    &__btn {
        margin: 0 auto;
        display: block;
        padding-top: 24px;
        padding-bottom: 22px; } }


