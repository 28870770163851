.modal {
    background-color: rgba(0,0,0,.5);
    &-content {
        &_user {
            padding: 30px 0;
            padding-top: 50px;
            position: relative;
            .close {
                position: absolute;
                right: 20px;
                top: 15px;
                font-size: 27px; } } } }
