.header {
    padding-top: 9px;
    padding-bottom: 21px;
    background-color: white;
    &-logo {
        margin-top: 3px;
        display: block;
        &__img {
            max-width: 265px;
            object-fit: cover; } }
    &-contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 17px;
        &__tel {
            color: #555555;
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 12px;
            text-decoration: none; }
        &__btn {
            border: none;
            background-color: transparent;
            padding: 0;
            color: #fb671f;
            margin-left: 5px;
            text-decoration: underline; } } }
.nav {
    margin-top: 25px;
    display: block;
    padding: 0;
    .menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        &-li {
            &__link {
                color: #555555;
                font-size: 16px;
                font-weight: 700;
                text-decoration: none;
                white-space: nowrap; } } } }
.navbar {
    &-toggler {
        left: 20px;
        top: 40px;
        width: 35px;
        height: 30px;
        border: none;
        border-top: 3px solid #fb671f;
        position: absolute;
        border-bottom: 3px solid #fb671f;
        border-radius: 0;
        span {
            top: 50%;
            position: absolute;
            background-color: #fb671f;
            transform: translateY(-50%);
            width: 100%;
            left: 0;
            height: 3px; } } }
