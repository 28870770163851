.choose {
    padding-top: 95px;
    margin-bottom: 70px;
    &-text {
        margin-bottom: 196px;
        &__title {
            text-shadow: 0 6px 6px rgba(0, 0, 3, 0.4);
            font-size: 66px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 14px;
            color: white;
            position: relative;
            z-index: 2;
            &:after {
                width: 137px;
                height: 137px;
                object-fit: cover;
                background-image: url('../img/garant.png');
                left: 400px;
                top: 0;
                transform: translateY(-48%); } }
        &__position {
            color: #ff6600;
            font-size: 28px;
            font-weight: 400; } } }
.packets {
    position: relative;
    &-buttons {
        display: flex;
        &__btn {
            font-size: 42px;
            font-weight: 300;
            text-transform: uppercase;
            color: #b27940;
            letter-spacing: 1.05px;
            width: 100%;
            background-color: white;
            border: none;
            padding: 25px 0;
            padding-bottom: 16px;
            background-color: #e3e3e3;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            text-align: center;
            &.active {
                background-color: white; }
            &_trend {
                color: #0ea41c; } } }
    .content {
        background-color: #fff;
        position: relative;
        padding: 27px 26px;
        padding-bottom: 0;
        &-top {
            margin-bottom: 10px; }
        &-text {
                position: absolute;
                z-index: 2;
                max-width: 739px;
                background-color: #b27940;
                background-image: linear-gradient(to right, #a36836 0%, #ebc78f 100%);
                color: white;
                padding: 38px 74px 17px 29px;
                right: 38px;
                margin-top: 39px;
                font-size: 15px;
                font-style: italic;
                line-height: 20px;
                opacity: .75;
                &:before {
                    width: 100%;
                    height: 100%;
                    border: 2px solid #a36836;
                    top: 17px;
                    left: -21px;
                    z-index: 1; }
                p {
                    padding-bottom: 4px;
                    &:last-child {
                        margin-bottom: 0; } }
                &_trend {
                    background-color: #b27940;
                    background-image: linear-gradient(to right, #00864a 0%, #57c292 100%);
                    opacity: 0.75;
                    &:before {
                        border: 2px solid #00864a; } } }
        &-item {
            padding-top: 245px;
            &_trend {
                padding-top: 302px; }
            &__li {
                display: flex;
                margin-bottom: 20px;
                img {
                    width: 69px;
                    height: 69px;
                    margin-right: 15px; }
                p {
                    margin-top: 12px;
                    font-size: 15px;
                    font-weight: 400; } } }
        &-prestige {}

        &-img {
 }            // margin-left: 26px
        .material {
            display: flex;
            align-items: flex-start;
            margin-bottom: 57px;
            &__img {
                margin-right: 12px;
                object-fit: contain; }
            &-item {
                margin-top: 55px;
                &__li {
                    margin-bottom: 10px;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #b27940;
                    position: relative;
                    padding-left: 10px;
                    max-width: 255px;
                    &:before {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 4px;
                        height: 4px;
                        background-color: #b27940;
                        border-radius: 50%;
                        left: 0;
                        top: 8px; }
                    &_trend {
                        color: #00864a;
                        &:before {
                            background-color: #00864a; } } } }
            &__descr {
                margin-top: 20px;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                color: #b27940;
                max-width: 243px;
                p {
                    margin-bottom: 12px;
                    &:last-child {
                        margin-bottom: 0; } }
                &_trend {
                    color: #00864a; } }
            &_two {
                margin-top: 38px;
                padding-right: 10px;
                .material__img {
                    margin-right: 47px; } } } } }
.calculate {
    background-color: #31917c;
    padding: 31px 0;
    &__btn {
        margin: 0 auto;
        display: block;
        padding-top: 24px;
        padding-bottom: 22px; } }




