html {
    overflow-x: hidden; }
body {
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden; }
::selection {
    background-color: #E8F9FF; }
.container {
    width: 100%;
    max-width: 1170px;
    padding: 0 15px;
    margin: auto; }
.container-fluid {
    width: 100%;
    padding: 0 15px; }
input, a, button {
    // outline: none
    &:focus {
 }        // outline: 1px solid yellow
    &:hover {} }
a {
    &:focus {
 } }        // outline: 1px solid yellow
.clearfix {
    &:after {
        content: '';
        display: block;
        visibility: hidden;
        height: 0;
        clear: both; } }
h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0; }
.aft {
    position: relative;
    &:after {
        content: '';
        display: block;
        position: absolute;
        background-repeat: no-repeat;
        background-size: 100% 100%; } }
.bef {
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        background-repeat: no-repeat;
        background-size: 100% 100%; } }
ul {
    padding: 0;
    margin: 0;
    li {
        padding: 0;
        margin: 0;
        list-style: none; } }
button {
    cursor: pointer; }
.btn {
    padding: 3% 4%;
    text-shadow: 0 2px 2px rgba(0, 0, 3, 0.14);
    color: #ffffff;
    font-size: 36px;
    font-weight: 400;
    color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 3, 0.21);
    background-color: #ff6600;
    border: none;
    border-radius: 5px; }
