.mainForm {
    padding-top: 111px;
    padding-bottom: 128px;
    background-image: url('../img/form-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    &-text {
        text-align: center;
        margin-bottom: 29px; }
    &__title {
        font-size: 48px;
        font-weight: 400;
        line-height: 48px;
        color: #ffffff;
        margin-bottom: 5px; }
    &__descr {
        color: #fca81e;
        font-size: 18px;
        font-weight: 400; }
    &-enter {
        margin-bottom: 29px;
        &__input {
            width: 100%;
            margin-bottom: 24px;
            box-shadow: inset 0 2px 7px rgba(21, 22, 18, 0.45);
            background-color: #ffffff;
            border: none;
            padding: 15px 13px;
            color: black;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            &:last-child {
                margin-bottom: 0; } }
        &__textarea {
            width: 100%;
            height: 100%;
            padding: 15px 13px;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: black; } } }

