.options {
    margin-bottom: 36px;
    &__title {
        margin-bottom: 73px;
        text-align: center;
        padding: 33px 0;
        color: #555555;
        font-family: Roboto;
        font-size: 54px;
        font-weight: 300;
        &:after {
            width: 98%;
            max-width: 241px;
            height: 3px;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: #555555; }
        &:before {
            width: 98%;
            max-width: 241px;
            height: 3px;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: #555555; } } }
.option {
    position: relative;
    margin-bottom: 60px;
    width: 100%;
    &-img {
        position: relative;
        img {
            object-fit: cover; } }
    &__title {
        position: absolute;
        top: 26px;
        right: 0;
        padding: 30px 36px 27px 36px;
        color: #ffffff;
        font-size: 54px;
        font-weight: 300;
        background-color: rgba(#ff6600, 0.7);
        text-align: right; } }


