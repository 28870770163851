.info {
    margin-bottom: 68px;
    &-top {
        flex-direction: column;
        * {
            margin: 0 auto; } }
    &__mark {
        object-fit: cover;
        width: 100%;
        max-width: 265px;
        margin-bottom: 30px; }
    &__btn {
        padding: 27px 121px;
        margin-bottom: 42px; } }
.person {
    &__img {
        width: 100%;
        object-fit: cover; }
    &-text {
        color: #111111;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        p {
            padding: 0;
            margin: 0;
            margin-bottom: 30px; } } }
.plus {
    &__img {
        margin: 0 auto;
        display: block;
        margin-bottom: 46px; }
    &__descr {
        color: #555555;
        font-size: 28px;
        font-weight: 400;
        text-align: center; } }

