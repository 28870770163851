@media (max-width: 1140px) {
    .header {
        &-contacts {
            margin-top: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            &__btn {
                margin-left: 0; } }
        &-logo {
            &__img {
                margin: 0 auto;
                display: block; } } } }
@media (max-width: 992px) {
    .packets {
        .content {
            padding: 15px;
            &-item {
                padding-top: 30px; }
            .material {
                &__img {
                    width: 45%;
                    margin-right: 20px; }
                &_two {
                    margin-top: 25px;
                    .material__img {
                        margin-right: 30px; } } } } }
    .fiveImages {
        margin-bottom: 150px;
        &-title {
            margin-bottom: 30px; } }
    .colors {
        align-items: center;
        &__img {
            width: 50%;
            margin-bottom: 20px; }
        &-title {
            &__descr {
                font-size: 30px; } } }
    .option {
        &__title {
            padding: 10px;
            font-size: 42px; } }
    .nav-box {
        position: static;
        .navbar {
            position: static; } } }
@media (max-width: 767px) {
    .person {
        &__img {
            margin-bottom: 20px; } }
    .header {
        padding-top: 10px;
        padding-bottom: 20px;
        .navbar {
            background-color: white; }
        .nav-box {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            background-color: white;
            z-index: 99;
            .navbar-collapse {
                padding: 60px 0;
                &.show {
                    // padding-top: 50px
                    background-color: white;
                    z-index: 99; } } }
        &-logo {
            padding-left: 15px;
            margin-bottom: 25px; }
        .menu {
            &-li {
                margin-bottom: 15px; } } }
    .btn {
        font-size: 18px; }
    .packets {
        .content {
            &-img {
                margin-bottom: 25px; }
            &-item {
                padding-top: 0; } } }
    .plus {
        margin-bottom: 20px;
        &__descr {
            font-size: 20px; } }
    .gallery {
        &-sertificate {
            &__img {
                margin-bottom: 20px; } }
        figure {
            margin-bottom: 20px; } }
    .advantages {
        &__title {
            font-size: 38px;
            line-height: 120%; }
        .advantage {
            &-text {
                font-size: 11px;
                p {
                    margin-bottom: 10px; } }
            &-main {
                margin-bottom: 10px;
                &__title {
                    font-size: 13px; } } } }
    .fiveImages {
        .image {
            img {
                width: 90%; } } }
    .colors {
        &-title {
            margin-bottom: 20px; } }
    .option {
        &__title {
            font-size: 20px; } }
    .contacts {
        &-info {
            margin-bottom: 15px; } }
    .mainForm {
        &-left {
            margin-bottom: 24px; } }
    .design {
        &__title {
            font-size: 38px;
            line-height: 120%; } }
    .fiveImages {
        &-title {
            img {
                width: 25%; }
            h3 {
                font-size: 25px; } }
        &-item {
            flex-flow: row wrap;
            justify-content: space-around;
            .image {
                margin-bottom: 15px; } } }
    .colors {
        &-title {
            &__numb {
                width: 25%; } } }
    .gradient {
        margin-bottom: 120px;
        &-title {
            top: -83%;
            &__numb {
                width: 75%; }
            &__descr {
                font-size: 35px; } } }
    .contacts {
        &__title {
            font-size: 38px; } } }
@media (max-width: 468px) {
    .gradient {
        &-title {
            &__numb {
                width: 40%; } } }
    .options {
        &__title {
            font-size: 30px; } }
    .design {
        &__title {
            font-size: 30px; }
        &__descr {
            font-size: 14px; } }
    .header {
        padding-top: 20px;
        padding-bottom: 10px;
        .nav-box {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            background-color: white; }
        &-logo {
            margin-left: 40px;
            &__img {
                width: 85%; } }
        .navbar {
            &-collapse {
                &.show {} } } }
    .choose {
        padding-top: 50px;
        &-text {
            margin-bottom: 100px;
            &__title {
                font-size: 36px; } } }
    .packets {
        &-buttons {
            &__btn {
                font-size: 20px; } }
        .content {
            &-text {
                position: relative;
                margin: 0;
                right: 0;
                width: 100%;
                padding: 15px;
                margin-bottom: 15px;
                &:before {
                    display: none; } }
            .material {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 0;
                padding-bottom: 20px;
                &-item {
                    margin-top: 30px; } } } }
    .btn {
        font-size: 15px;
        padding: 15px; }
    .why {
        &__title {
            font-size: 38px; } }
    .advantage {
        flex-direction: column;
        &-img {
            width: 100%; }
        &-text {
            width: 100%; } }
    .galleryAdv {
        margin-bottom: 50px; }
    .color__title {
        font-size: 13px;
        line-height: 110%; } }
